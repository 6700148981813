"use strict";

$(document).ready(function () {
  $("#accordion .accordion-btn > a").on("click", function () {
    $("#accordion .accordion-btn > a").find(".text").html("Detay");

    if ($(this).hasClass("collapsed")) {
      $(this).find(".text").html("Kapat");
    } else {
      $(this).find(".text").html("Detay");
    }
  });
});
"use strict";

// Opera 8.0+
var isOpera = !!window.opr && !!opr.addons || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0; // Firefox 1.0+

var isFirefox = typeof InstallTrigger !== 'undefined'; // Safari 3.0+ "[object HTMLElementConstructor]" 

var isSafari = /constructor/i.test(window.HTMLElement) || function (p) {
  return p.toString() === "[object SafariRemoteNotification]";
}(!window['safari'] || typeof safari !== 'undefined' && safari.pushNotification); // Internet Explorer 6-11


var isIE =
/*@cc_on!@*/
false || !!document.documentMode; // Edge 20+

var isEdge = !isIE && !!window.StyleMedia; // Chrome 1 - 71

var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime); // Blink engine detection

var isBlink = (isChrome || isOpera) && !!window.CSS;

if (isOpera === true) {
  document.body.classList.add("opera");
}

if (isFirefox === true) {
  document.body.classList.add("firefox");
}

if (isSafari === true) {
  document.body.classList.add("safari");
}

if (isIE === true) {
  document.body.classList.add("ie");
}

if (isEdge === true) {
  document.body.classList.add("ie-edge");
}

if (isChrome === true) {
  document.body.classList.add("chrome");
}

if (isBlink === true) {
  document.body.classList.add("isBlink");
}
// if (document.getElementById("calendar") !== null) {
//   document.addEventListener("DOMContentLoaded", function () {
//     var calendar = new FullCalendar.Calendar(document.getElementById("calendar"), {
//       locale: "tr",
//       firstDay: 1,
//       contentHeight: 217,
//       editable: false,
//       header: {
//         start: "title",
//         center: "",
//         end: "prev next dayGridMonth,listMonth"
//       },
//       events: [
//         {
//           title: 'event1',
//           start: '2022-04-08',
//           allDay: false
//         },
//         {
//           title: 'event3',
//           start: '2022-03-31',
//           allDay: false
//         },
//         {
//           title: 'event3',
//           start: '2022-04-21',
//           allDay: false
//         }
//       ],
//       displayEventTime: true
//     });
//     calendar.render();
//     let fixMonthTitle = function () {
//       let title = $("#fc-dom-1")[0];
//       if ($(title).find("span").length > 0) {
//         $(title).find("span").remove();
//       }
//       let text = title.innerHTML.split(" ");
//       let newTitle = "<span>" + text[0] + "</span> " + "<span>" + text[1] + "</span>";
//       monthTitle = newTitle;
//       $(title).html(newTitle)
//     }
//     fixMonthTitle();
//     $(".fc-button").click(function () {
//       fixMonthTitle();
//     });
//   });
// }
// if (document.getElementById("eventcalendar") !== null) {
//   document.addEventListener("DOMContentLoaded", function () {
//     var eventCalendar = new FullCalendar.Calendar(document.getElementById("eventcalendar"), {
//       locale: "tr",
//       firstDay: 1,
//       editable: false,
//       dayMaxEventRows: true,
//       noEventsContent: "Görüntülenecek etkinlik yok.",
//       buttonText: {
//         month: 'Ay',
//         listWeek: 'Hafta',
//         listDay: 'Gün'
//       },
//       moreLinkContent: function (args) {
//         return '+' + args.num + ' daha fazla';
//       },
//       views: {
//         dayGridMonth: {
//           eventLimit: 3,
//         }
//       },
//       headerToolbar: {
//         left: '',
//         center: 'dayGridMonth,listWeek,listDay',
//         right: 'prev next title'
//       },
//       events: [
//         {
//           title: 'Kişisel Gelişimle Alakalı Etkinlik',
//           start: '2022-06-27T12:30:00',
//           end: '2022-06-27T17:30:00',
//           allDay: false
//         },
//         {
//           title: 'event4',
//           start: '2022-06-27T15:30:00',
//           end: '2022-06-27T17:30:00',
//           allDay: false
//         },
//         {
//           title: 'event4',
//           start: '2022-06-27T15:30:00',
//           end: '2022-06-27T17:30:00',
//           allDay: false
//         },
//         {
//           title: 'event4',
//           start: '2022-06-27T15:30:00',
//           end: '2022-06-27T17:30:00',
//           allDay: false
//         },
//         {
//           title: 'event4',
//           start: '2022-06-27T15:30:00',
//           end: '2022-06-27T17:30:00',
//           allDay: false
//         },
//         {
//           title: 'event4',
//           start: '2022-06-27T15:30:00',
//           end: '2022-06-27T17:30:00',
//           allDay: false
//         },
//         {
//           title: 'event4',
//           start: '2022-06-27T15:30:00',
//           end: '2022-06-27T17:30:00',
//           allDay: false
//         },
//         {
//           title: 'event2',
//           start: '2022-06-01T12:30:00',
//           end: '2022-06-01T19:30:00',
//           allDay: false
//         },
//         {
//           title: 'event3',
//           start: '2022-06-24T12:30:00',
//           end: '2022-06-24T20:30:00',
//           allDay: false
//         }
//       ],
//       displayEventTime: true
//     });
//     eventCalendar.render();
//   });
// }
// //event calendar detail daygrid-top day-name added.
// function calendarDayName() {
//   $('#eventcalendar .fc-daygrid-day').each(function () {
//     if ($(this).hasClass('fc-day-mon')) {
//       $(this).find(".fc-daygrid-day-top").append('<span class="day-name">' + $(this).parents('#eventcalendar').attr('data-mon') + '</span>');
//     } else if ($(this).hasClass('fc-day-tue')) {
//       $(this).find(".fc-daygrid-day-top").append('<span class="day-name">' + $(this).parents('#eventcalendar').attr('data-tue') + '</span>');
//     } else if ($(this).hasClass('fc-day-wed')) {
//       $(this).find(".fc-daygrid-day-top").append('<span class="day-name">' + $(this).parents('#eventcalendar').attr('data-wed') + '</span>');
//     } else if ($(this).hasClass('fc-day-thu')) {
//       $(this).find(".fc-daygrid-day-top").append('<span class="day-name">' + $(this).parents('#eventcalendar').attr('data-thu') + '</span>');
//     } else if ($(this).hasClass('fc-day-fri')) {
//       $(this).find(".fc-daygrid-day-top").append('<span class="day-name">' + $(this).parents('#eventcalendar').attr('data-fri') + '</span>');
//     } else if ($(this).hasClass('fc-day-sat')) {
//       $(this).find(".fc-daygrid-day-top").append('<span class="day-name">' + $(this).parents('#eventcalendar').attr('data-sat') + '</span>');
//     } else if ($(this).hasClass('fc-day-sun')) {
//       $(this).find(".fc-daygrid-day-top").append('<span class="day-name">' + $(this).parents('#eventcalendar').attr('data-sun') + '</span>');
//     }
//   });
// }
// function calendarDayNameClear() {
//   $('#eventcalendar .fc-daygrid-day').find(".fc-daygrid-day-top span.day-name").remove();
// }
// $(document).ready(function () {
//   calendarDayName();
//   $('#eventcalendar .fc-button').click(function () {
//     calendarDayNameClear();
//     calendarDayName();
//   })
// });
"use strict";
"use strict";

$(window).scroll(function () {
  var sticky = $('header.homepage'),
      scroll = $(window).scrollTop(),
      headerHeight = sticky.height();
  if (scroll >= 1) sticky.addClass('fixed');else sticky.removeClass('fixed');
});
"use strict";

$(document).ready(function () {
  $(document).on('click', '#headerOpener', function () {
    $('header.left-header').addClass('open');
  });
  $(document).mouseup(function (e) {
    var container = $('header.left-header');

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.removeClass('open');
    }
  });
  $(document).on('click', '#announcementOpener', function () {
    $('aside').addClass('open');
  });
  $(document).mouseup(function (e) {
    var container = $('aside');

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.removeClass('open');
    }
  });
  $(document).on('click', '#notificationOpener', function () {
    $('.notification-dropdown').addClass('open');
  });
  $(document).mouseup(function (e) {
    var container = $('.notification-dropdown');

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.removeClass('open');
    }
  });
  $(document).on('click', '#notificationDropdownClose', function () {
    $('.notification-dropdown').removeClass('open');
  });
  $(document).on('click', 'header.homepage .menu-bottom .mobile-menu a', function () {
    $('header.homepage .menu-bottom .menu').addClass('open');
  });
  $(document).mouseup(function (e) {
    var container = $('header.homepage .menu-bottom .menu');

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.removeClass('open');
    }
  });
  $(document).on('click', 'header.homepage .menu-bottom .menu .mobile-menu-close, header.homepage .menu-bottom .menu li a', function () {
    $('header.homepage .menu-bottom .menu').removeClass('open');
  });
});
// $('.upcoming-events-slick').slick({
//   infinite: false,
//   slidesPerRow: 1,
//   rows: 2,
//   vertical: false,
//   dots: true,
//   swipeToSlide: true,
//   appendDots: '.events-dots',
//   prevArrow: '.upcomingEventsPrevButton',
//   nextArrow: '.upcomingEventsNextButton',
// });
// $('.news-slick').slick({
//   infinite: false,
//   slidesPerRow: 1,
//   rows: 2,
//   vertical: false,
//   dots: true,
//   swipeToSlide: true,
//   appendDots: '.news-dots',
//   prevArrow: '.newsPrevButton',
//   nextArrow: '.newsNextButton',
// });
// $('.announcement-slick').slick({
//   infinite: false,
//   slidesPerRow: 1,
//   rows: 2,
//   vertical: false,
//   dots: true,
//   swipeToSlide: true,
//   appendDots: '.announcement-dots',
//   prevArrow: '.announcementPrevButton',
//   nextArrow: '.announcementNextButton',
// });
// $('.tasks-slick').slick({
//   infinite: false,
//   slidesToShow: 3.5,
//   slidesToScroll: 3.5,
//   vertical: false,
//   dots: false,
//   swipeToSlide: true,
//   arrows: false,
//   variableWidth: true,
//   responsive: [
//     {
//       breakpoint: 420,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         variableWidth: false,
//       }
//     }
//   ]
// });
// $('.online-education-slick').slick({
//     infinite: false,
//     slidesToShow: 3.5,
//     slidesToScroll: 3.5,
//     vertical: false,
//     dots: false,
//     swipeToSlide: true,
//     arrows: false,
//     variableWidth: true,
//     responsive: [
//         {
//             breakpoint: 420,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//                 variableWidth: false,
//             }
//         }
//     ]
// });
// $('.projects-slick').slick({
//   infinite: false,
//   slidesToShow: 3.5,
//   slidesToScroll: 3.5,
//   vertical: false,
//   dots: false,
//   swipeToSlide: true,
//   arrows: false,
//   variableWidth: true,
//   responsive: [
//     {
//       breakpoint: 420,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         variableWidth: false,
//       }
//     }
//   ]
// });
// $('.job-posting-slick').slick({
//   infinite: false,
//   slidesToShow: 3.5,
//   slidesToScroll: 2.5,
//   vertical: false,
//   dots: false,
//   swipeToSlide: true,
//   arrows: false,
//   variableWidth: true,
//   responsive: [
//     {
//       breakpoint: 449,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         variableWidth: false,
//       }
//     }
//   ]
// });
// $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
//   $('.job-posting-slick').slick('setPosition');
// })
"use strict";
"use strict";